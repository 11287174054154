body {
  	margin: 0;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;

	font-size: .9rem;
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

@font-face {
	font-family: 'JetBrains Regular';
	src: url('./fonts/JetBrainsMono-Regular.ttf')
}

@font-face {
	font-family: 'JetBrains Extra Light';
	src: url('./fonts/JetBrainsMono-ExtraLight.ttf')
}

@font-face {
	font-family: 'JetBrains Semi Bold';
	src: url('./fonts/JetBrainsMono-SemiBold.ttf')
}

@font-face {
	font-family: 'JetBrains Thin';
	src: url('./fonts/JetBrainsMono-Thin.ttf')
}

@font-face {
	font-family: 'TitilliumWeb';
	src: url('./fonts/TitilliumWeb.ttf')
}

/* CONSTANTS */
:root {

	font-family: 'TitilliumWeb';

	/* COLORS */
	--color-primary: #09EA81;
	--color-secondary: #A0E114;
	--color-darkest: #143714;
	--color-dark: #058237;

	--status-autorizada: var(--color-secondary);
	--status-denegada: var(--orange);
	--status-cancelada: var(--color-danger);

	/* Greys */
	--grey-darkest: #0F140F;
	--grey-dark: #1E281E;
	--grey-medium: #323732;
	--grey-medium-light: #595a59;
	--grey-regular: #9ea89e;
	--grey-regular-light: #e0e0e0;
	/* --grey-regular: #A5AFA5; */
	--grey-light: #DADADA;
	--grey-lighter: #eceeec79;
	--grey-lightest: #f3faef;

	/* Common states */
	--color-success: #09EA81;
	--color-attention: #FAC805;
	--color-danger: #FF1978;
	--color-info: #4bbdff;
	--color-neutral: #CCC;

	/* Basics */
	--white: #fdfdfd;
	--black: #000;

	/* Others */
	--blue: #0e95e2;
	--calendar-blue: #31b0d5;
	--red: #ff0000;
	--orange: #ff8800;
	--color-link: --color-primary;
	--color-app-background: #f7f7f7;
	/* --color-app-background: #fdfdfd; */
}

/* COMMON CSS */
.icon-button {
	height: 30px;
	min-width: fit-content;
	width: 120px;
	
	border: 1px solid var(--grey-light);
	border-radius: 8px;
	
	text-align: center;
	text-transform: uppercase;

	font-size: .85rem;
	font-family: 'TitilliumWeb';
	line-height: 24px;
	
	font-weight: bold;
	letter-spacing: .3px;

	white-space: nowrap;
	
	padding-left: 12px;
	padding-right: 12px;

	background-color: var(--white);

	user-select: none;

	transition: color .15s ease-in, opacity .15s ease-in, background-color .2s ease-in;
}

.action-button {
	min-width: fit-content;
	
	color: var(--white);
	white-space: nowrap;
	font-size: .85rem;
	line-height: 28px;
	
	border: none;
	background-color: var(--color-primary);
}

.action-button:active {
	background-color: #0cd175 !important;
}

.action-button:hover {
	border: none !important;
}

.icon-button:hover {
	border: 1px solid var(--color-primary);
	cursor: pointer;
	opacity: 1;
}

.icon-button:active {
	transition: background-color .0s ease-in;
	background-color: var(--color-primary);
	color: var(--white);
}

.icon-button:disabled {
	transition: background-color .0s ease-in;
	background-color: var(--grey-light);
	color: var(--white);
	cursor: not-allowed;
}

.icon-button i {
	font-size: .98rem;
	line-height: 30px;
}

.icon-button span {
	margin-left: 6px;
	margin-top: auto;
	margin-bottom: auto;
}

.negative-action-btn:hover {
	border-color: var(--color-danger);
}

.negative-action-btn:active {
	background-color: var(--color-danger);
	color: var(--white);
}

.table-action {
	font-size: 1rem;
	margin-right: 6px;
	color: var(--grey-medium);
}

.action-positive:hover {
	color: var(--color-primary) !important;
}

.action-negative:hover {
	color: var(--color-danger) !important;
}

/* Customizing checkboxes */
input[type="checkbox"]:after {
  content: " ";
  background-color: var(--color-primary);
  display: inline-block;
  visibility: visible;
}

input[type="checkbox"]:checked:after {
  height: 14px;
  width: 14px;
  
  border-radius: 3px;
  display: block;
  
  content: "\2714";
  font-size: 0.9rem;
  line-height: 14px;
  text-align: center;
  color: #fff;

  transform: translate(-0.5px, -0.5px);
}

/* Customizing radio buttons */
input[type="radio"]:checked:after {
  height: 15px;
  width: 15px;

  display: block;
  background-color: #fff;

  border: none;
  border-radius: 0;

  content: "\29BF";
  font-size: 1.2rem;
  line-height: 14px;
  text-align: center;
  font-weight: 700;
  text-indent: -0.1rem;
  color: var(--color-primary);
}

input:focus-visible {
	outline: none;
}

input[type="number"], input[type="text"], input[type="password"], textarea {
	height: 30px;
	
	padding: 0 10px;
	
	border: 1px solid var(--grey-light);
	border-radius: 8px;

	font-family: 'TitilliumWeb';
}

textarea {
	padding: 10px 8px;
	resize: none;
}

input[type="radio"]:hover, input[type="checkbox"]:hover {
	cursor: pointer !important;
}

input[type="text"]:focus-visible, textarea:focus-visible {
	outline: none;
}

input[type="radio"]:after:hover {
	cursor: pointer;
}

input[type="radio"]:after {
  height: 13px;
  width: 13px;
  
  border: 1px solid var(--color-dark);
  border-radius: 50%;
  
  content: " ";
  display: inline-block;
  visibility: visible;
  background-color: #fff;
}

.input-label {
	color: var(--grey-regular);
	margin-bottom: 2px;
	padding-left: 6px;
}

 /* Scrollbar */
*::-webkit-scrollbar {
  width: 12px !important;
}

*::-webkit-scrollbar-track {
  background: transparent !important;
}

*::-webkit-scrollbar-thumb {
  background: rgb(224, 224, 224) !important;
  border: 5px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
  border-radius: 6px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgb(209, 209, 209) !important;
  border: 3px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
  border-radius: 5px !important;
  transition: all .2s ease-in !important;
}

/* Removing selection arrows for inputs type 'number' */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.menu-item {
	height: 28px;
	line-height: 28px;
	margin: 0;
	padding: 6px 16px !important;
}
.menu-item:hover {
	background-color: var(--grey-lightest);
	cursor: pointer;
}
.menu-item.disabled {
	opacity: 0.4;
	pointer-events: none;
}

.MuiPaper-root {
	border-radius: 8px !important;
}

.MuiPaper-root .customize-columns-menu {
	height: fit-content;
	width: 220px;
	
	padding-bottom: 2px;

	display: flex;
	flex-direction: column;
	
	border: 1px solid var(--grey-light);
	border-radius: 8px;
}

.MuiPaper-root .customize-columns-menu .menu-header {
	
	height: 42px;
	border-bottom: 1px solid var(--grey-lightest);
	padding: 10px 26px 10px 10px;
	
	display: flex;
} 

.MuiPaper-root .customize-columns-menu .menu-header i {
	font-size: 1.1rem;
	color: var(--grey-regular);
	
	margin-left: 10px;
	transform: translate(12px, -4px);
}

.MuiPaper-root .customize-columns-menu .menu-header > span {
	font-size: .9rem;
	color: var(--grey-dark);
	opacity: .9;
}

.MuiPaper-root .customize-columns-menu .menu-items-frame {
	max-height: calc(100%);
	width: 100%;
	overflow-y: auto;
	margin-top: 4px;
}

.MuiPaper-root .customize-columns-menu .menu-items-frame > div {
	height: 14px;
	min-width: 200px;
}

.MuiPaper-root .customize-columns-menu .menu-items-frame > div:hover {
	background-color: var(--grey-lightest);
	cursor: pointer;
	
}

.MuiPaper-root .customize-columns-menu .menu-items-frame > div > span {
	margin-left: 10px;
	height: 34px;
	line-height: 34px;
}

.MuiChip-root {
	background-color: var(--grey-lightest) !important;
	border-radius: 8px !important;
	font-size: .9rem !important;
}

/* REACT DATE PICKER */
.react-calendar {
	line-height: .2rem !important;
	width: 305px !important;
}

.react-calendar__navigation {
	margin-bottom: 2px;
}

.react-date-picker__wrapper {
	height: 34px;
	width: 158px;
	padding: 4px;
	border-radius: 6px;
	border: 1px solid var(--grey-light);
	background-color: var(--white);
}
	
.react-date-picker__wrapper input[type="number"] {
	height: 100%;
	border: none !important;
	padding: 0 1px;
	font-family: 'TitilliumWeb';
}

.react-date-picker__inputGroup {
	margin-right: 0px;
	cursor: pointer;
}

.react-date-picker__inputGroup__input:focus-visible {
	outline: none;
	color: var(--color-primary);
}

.react-date-picker__inputGroup__input:invalid {
	background-color: white;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
	background-color: var(--color-primary) !important;
}

.react-date-picker__calendar {
	z-index: 4;
}